export default {
    'Terms of Service': [
        `The following terms of service apply exclusively to the Services covered by these Terms and Conditions. For Cabbagino services, Cabbagino is directly the payment service provider. Cabbagino is not responsible for the actions of Your recipient of the transfer or for the subject of the transfer. We do not guarantee the authenticity of any Cabbagino user or that that the sender or recipient can make or complete the transaction.`,

        'CABBAGINO is not a credit institution (bank) and Customer’s Account is not a bank account',

        'You acknowledge and agree that these Terms and Conditions applies only between You and Cabbagino.',
    ],
    'Eligibility and account registration': [
        `To be eligible to use the Service, You may only create an account through our website. In accordance with our Privacy Policy, in order to register, create and use an account, the Company may require You to provide certain personal information, including but not limited to Your name, nationality, email address, mobile / cell phone number, residential address or postal code, date of birth and corresponding government-issued photo ID number (passport, driver’s license, ID) for Cabbagino. During the registration process or when accessing the Services from a mobile device, Your IP address or device ID is also stored. You agree that Your personal information provided to Cabbagino at registration and later is true, accurate, up-to-date and complete. You agree to maintain and update Your personal information so that it is always accurate, true and complete.`,

        `The Company may further request any information that is necessary among other things also in accordance with anti-money laundering laws and regulations and the Customer understands and accepts the requirement to provide the company with such information within 3 (three) working days, otherwise the Company reserves the right to suspend and/or close Account.`,

        `You acknowledge that the Company is not obliged to accept and/or authorize You. The Company shall determine the scope of information which may be requested from You and to decide on which terms and conditions the Customer is accepted and authorized. In case any other information is needed to verify the Customer or any action done by the Customer in connection with services provided by the Company, the latter reserves the right to request any other information from the Customer and the Customer consents to provide this information within 3 (three) working days after receiving such request. If the information is not provided, the Company reserves the right to unilaterally suspend or terminate provision of the Services to the Customer.`,

        `Once the Customer is accepted and authorized by the Company, the Customer shall be provided with the Account with Cabbagino.`,
    ],
    'Account types': [
        `We offer two different types of accounts: personal and business.`,

        `You can only have one personal account. Some functions of Personal Accounts may be limited depending on how You want to use the services, how much funds You need to transfer and what information has been provided to us. We may require You to provide additional information to complete the transaction. Business, commercial or trade transactions must not be conducted by means of Personal Accounts.`,

        `For each business You deal with, You may only have one business account; its use is limited based on Your current verification status. Business accounts must be requested and authorized by Cabbagino. By opening a Business Account and accepting the terms and conditions set forth in these terms and Conditions, including but not limited to the Rules and Restrictions for Business Accounts, set out below, You acknowledge that neither You nor Your business create a Business Account in the first place for personal use, family or household purposes.`,

        `Only You have the right to access Your Account and to use our services within it only in the frames of stated and declared activity and purposes. We may cancel or block Your transactions or hold Your funds if You violate the terms of these Terms and Conditions, including but not limited to the use of the original account for business purposes or business account for personal, family or household purposes.`,

    ],
    'Account authorization': [
        `You may access Your Account via website by entering Login authorization data provided to You by Cabbagino. In case of any problems with Login authorization or any suspicious that Your Login authorization data was obtained by third party, please contact our support team <a href="mailto:support@cabbagino.com">support@cabbagino.com</a>.`,

        `When entered data is verified by Cabbagino, You will have access to the Account and You will be able to make transactions via provision of payment transactions\` instructions to Cabbagino. You acknowledge that Cabbagino will not be liable for any error with respect to the instructions You provide.`,

        `You must keep your authorization data secret and fully confidential. You shall be responsible for the loss or misuse of any Login authorization data and shall bear exclusive liability for any consequences of their use by unauthorized persons. Any access to the Account by entering Login authorization data shall be deemed as access performed by the Customer. The Company reserves the right to request additional information.`,

    ],
    'Personal identification': [
        `You hereby authorize Cabbagino to direct, straight or through third parties, any requests we deem necessary to verify Your identity and account information, and for Your company or employer’s business accounts. This may include requesting additional information and / or documentation about the use or identification of Your account, or proof of ownership of the specified email address, phone number or financial instruments, and verification of Your information through third-party databases or other sources.`
    ],
    'Cabbagino Account': [
        `Your Cabbagino Account balance consists of the funds in Your Cabbagino account that are available for new transfers or withdrawals and are not part of pending transactions. When You make a payment or withdrawal from Your Cabbagino account, the system will automatically check whether Your Cabbagino account balance will cover the transaction. If there are not enough funds to perform the transaction, the transaction will not be performed. You can fund Your Cabbagino account by bank transfer, debit or credit card, or other approved funding method. These deposited funds, once credited, will be available for future transfers. Transactions will only be made if Your Cabbagino account is sufficient to cover the payment. If Your Cabbagino account does not have sufficient funds to cover the transaction, we suggest that You use the primary funding source You specified to fund Your account and complete the payment in full. If You are unwilling to affect Your balance, You can leave it on the account or withdraw it at any time.`,

        {
            'Transaction history': [
                `You can access Your transaction history by logging into Your Cabbagino account. You agree to view Your transaction history online and not receive periodic statements sent by mail.`,

                `Transaction history includes charges, fees and margins, as well as indicates statues of the Transactions as completed, cancelled or pending.`,
            ]
        }
    ],
    'Movement of funds': [
        `We may, at our discretion, impose limits on the amount of money You can send or receive, deposit or withdraw using our Services and / or during a certain period of time. Please contact our support team <a href="mailto:support@cabbagino.com">support@cabbagino.com</a> for more information on limits. If Your identity has been verified, we may increase Your limits for sending and / or receiving funds. These restrictions may change from time to time at our discretion at our sole discretion, and requirements for cancellation or changes may differ depending on product use and / or local regulations.`,

        `As part of its risk management, Cabbagino may limit the funding sources available for Your use. For example, we may limit Your funding sources for a specific transaction by debit cards or checking account.`,

        `When Your regular bank account is used as a funding source, You request that we initiate an electronic transfer on Your behalf from Your bank account. For these transactions, we conduct wire transfers to the amount You specify from Your bank account. You agree that such requests represent Your permission for the Company to make such transfers, and once You have given Your permission to transfer, You will not be able to cancel the wire transfer and Cabbagino will be able to resend any authorized debit that was returned due to insufficient funds, unless otherwise stated applicable law. If You use Your credit or debit card to fund Your Cabbagino account, card issuers may treat such transactions as a cash advance and charge a commission. Cabbagino is not responsible for such payments and the user assumes sole responsibility for payment of such fees.`,

        {
            'Return transactions': [
                `When You transfer money, the recipient is not obligated to accept it. You agree that Cabbagino will not be liable for any losses resulting from the recipient’s decision not to accept a transaction made through the Cabbagino Services.`,

                `The recipient of Cabbagino funds may not immediately accept funds. During this period, Your account will be held with the transfer amount and any related fees, if any. Once the recipient has successfully accepted the transaction, the transfers will be automatically debited from Your account and credited to the recipient’s account. You also have the option to cancel Your payment at any time using the Services, prior to acceptance by the recipient. If for any reason the payment is unclaimed, declined or returned, we will refund the money to Your balance or to the original funding source.`,
            ],
        },

        {

            'Tariffs and limits for sending and receiving money': [
                `You may use the Services to send funds provided by Your Cabbagino account balance. Contributions may change from time to time at our sole discretion. You may be subject to third party fees such as underfill fees, refunds or other fees that may be charged by the bank if Your payment is rejected.`,

                `Cabbagino may also, at its discretion, set daily, weekly or monthly limits on the amount of money that can be sent or received through the app. These limits are usually, though not always, based on local legal regulations.`,
            ]
        }
    ],
    'Account balance, deposit, withdrawal of funds': [
        {
            'Account balance': [
                `If the balance is positive, we will keep Your funds separate from our corporate funds and will not use them for our operating expenses. This will not lead to voluntary transfer of Your funds to creditors in the event of bankruptcy. As long as Your funds are in our possession, Cabbagino will pool Your funds with funds of other users and will place these pooled accounts in one or more bank accounts in the name of Cabbagino or in other liquid securities, etc`,

                `The Company does not represent a bank or other registered custodian institution; it offers online digital banking services. Funds held by the Company or its service providers, including any banking service providers, related to the processing of transactions are not deposit obligations and are not insured to the benefit of the user by any government agency.`,
            ]
        },

        `When You transfer funds from Your Cabbagino account to the associated account (s) of a financial institution, the applicable laws and regulations will govern the transfer.`,

        {
            'Deposit of funds': [
                'Cabbagino also reserves the right to charge users a fee for replenishing their Cabbagino account. The nature and amount of such fees are at Cabbagino’s discretion.',

                'Cabbagino makes every effort to inform users of these fees be means of all available channels: email, push notifications and via the app.',

                `As we sometimes route our credit card processing to overseas banks, Your bank may charge You fees for international foreign currency transactions or overseas transactions.`,

                `The company is also not responsible for cases when Your bank may classify a deposit as a ‘cash advance’.`,

                `To deposit funds You can be requested to provide information for verification and validation purposes.`,
            ],
        },

        {
            'Investigation of payments': [
                `Investigation of payments is the process conducted by Cabbagino to verify certain potentially high risk transactions. If the payment is subject to investigation, the Company will hold the payment and may notify the recipient. The company will conduct a verification and either confirm or cancel the payment. If the payment is confirmed, we will provide the recipient will be notified accordingly. Otherwise, the Company will cancel the payment and the funds will be refunded. We will notify You by email and / or through Your Cabbagino account if the payment is canceled.`
            ],
        },

        {

            'Withdrawal of funds': [
                `You can withdraw funds from Your Cabbagino account by transferring them electronically to Your bank account. We may limit withdrawals from Your debit card depending on which product is enabled and approved for Your account. We may also limit amounts of withdrawal funds at our sole discretion.`,

                `To withdraw funds, You can be requested to provide information for verification and validation purposes.`,

                `You acknowledge and accept that Cabbagino may only proceed with Withdrawal of Your funds into account that is held in Your name (You are account holder and beneficiary of the account) at credit institution.`,

            ],
        },


        {
            'Withdrawal limits': [
                `We reserve the right to hold withdrawals while we are checking for risk or asking You to provide additional information to verify Your identity; we also have the right to limit the amount withdrawn until the information is verified.`,

                `We have the right, at our discretion, to impose limits on the amount of money You can withdraw from Your Cabbagino account within a specified period of time. These limits may change from time to time at our discretion.`,

                `Cabbagino also reserves the right to charge commissions or fees for withdrawals at its sole discretion. We will endeavor to keep users informed of such charges through all available channels: email, push notifications and via the app.`
            ],

        },

        {
            'Risk of cancellation, withdrawn payments and claim': [
                `Upon receipt of payment, You are liable to us for the full amount of the payment, plus any fees if the payment is later found to be invalid for any reason. This means that in addition to any other liability, You will be liable for the amount of the payment as well as the applicable fees if You lose on a claim or chargeback, or if the payment is canceled. If the sender of the payment later disputes the payment or files a chargeback claim, the debit or credit card issuer or the sending bank, and not the Company, will determine whether the dispute is valid and who is due. You agree to allow Cabbagino to recover the amounts due to us by debiting funds from Your balance. If Your balance does not have sufficient funds to cover Your liability, You agree to reimburse the required by other means. If Cabbagino is unable to recover the funds from Your primary source of funding, we are going to contact You. Cabbagino may return funds from any other funding source or take other legal action to recover the amount due to the extent permitted by applicable law.`,
            ],
        },


        {
            'Card information and processing':

                [
                    `We process transactions funded by Your debit card through the debit ATM debit card network or Visa / MasterCard network of our choice`,

                    `If Your credit card number or expiration date changes, we may obtain this information from our financial services partner and update Your account.`
                ],
        }
    ],
    'Closing of an account': [
        `In the event of no active or pending transactions, You can close Your account at any time by contacting us in writing or by email and requesting the closure of Your Cabbagino account. Any amount left on the account or pending as well as a statement from Cabbagino account will be sent to the user by bank transfer to the specified bank account, unless otherwise specified. Customer information will be stored in our database for at least 5 (five) years.`,

        `You may not close Your account to avoid a payment investigation. If You attempt to close Your account during an investigation, we may hold Your funds for up to 180 (one hundred and eighty) days to protect Cabbagino or a third party from the risk of cancellation, refunds, claims, fees, penalties, charges or other obligations. You will be responsible for all obligations associated with Your account, even after the account is closed.`,

        `Any funds left on Your Cabbagino account at the time of its closure will be returned to You by bank transfer after deducting any fees or charges. Existing balance will not be managed in cash.`,

        `If Cabbagino close Customer’s account, Cabbagino will redeem any unrestricted or undisputed amount of funds in Customer’s account according to these Conditions.`,

        `If for any reason we restrict access or close Your account or stop Your use of our Services, You may contact us and request restoration of access, if necessary. However, if we believe that You have violated these Terms and Conditions, restoration will be subject to our discretion. You may at any time stop using the Services or close Your accounts by contacting us. The Company, in its sole discretion, reserves the right to terminate provision of the Services or revoke Your access to the Services for any reason and at any time. If, for any reason, we stop or restrict Your use of our Services, we will use commercially reasonable efforts to notify You of our actions.`,

        `If Cabbagino incurs any loss because You violate our policies, laws or otherwise financially harm Cabbagino, we may withhold Your funds for up to 180 days, fine You for each such violation, and take legal action against Your claim to recover additional damages, investigation costs or legal expenditures that we may incur. You acknowledge and agree that a fine of $2,500 for breach of these Terms and Conditions is currently a reasonable minimum estimate of damage to Cabbagino, taking into account all existing circumstances, including the ratio of the amount to the possible range of damage to Cabbagino and the expectation that proof of actual damage may be impractical or extremely difficult Cabbagino may deduct such penalties directly from any existing balance in the offender’s account or any other Cabbagino account that You control.`,
    ],
    'Suspending access to an account': [
        `Cabbagino may at its discretion suspend or otherwise restrict the functionality of Customer’s account and Customer’s right to request transactions and receive money transfers or close Customer’s account at any time without any prior notice, including, but not limited to the suspicion of unauthorised or fraudulent use of Customer’s account or that Customer’s account has been accessed without Customer’s authorization, breach of these Terms and Conditions or legal acts and/or regulations, abuses of the reversal or chargeback process through Customer’s bank or credit card company, refuses when requested to cooperate with an investigation or to provide adequate confirmation of identity or other identity or security information, in cases Cabbagino is complying with money laundering or terrorist financing investigations conducted by government authorities, agencies or commissions, insolvency or bankruptcy of the Customer or Customer\`s commercial activities are suspended or terminated any, Customer has offered threats or have been abusive to Cabbagino staff, other reasonable considerations that there is any suspicious, fraudulent or illegal activity on the Account.`,

        `If Cabbagino suspects that Customer is engaging in an activity referred to these Conditions, Cabbagino may contact the relevant government authority, recognised crime prevention body and other third parties and disclose details of any prohibited activities, under Cabbagino Privacy Policy or any applicable law and/or take legal action against Customer.`,
    ],
    'Holding of funds': [
        `Cabbagino may, at its sole discretion, suspend a payment if we believe there may be a high level of risk associated with it. If Cabbagino suspends Your payment, it will appear as ‘pending’ in Your Cabbagino account.`,

        `We will unblock any delayed payment after 21 days, unless we receive a dispute, claim, chargeback or cancellation of a held transaction. We may release the block earlier if the sender of the funds provides us with confirmation, Cabbagino will confirm the correctness of the payment, or otherwise complete an investigation.`,

        `If You receive a dispute, claim, chargeback or cancellation of a transaction whose payment is withheld, Cabbagino may hold the payment in Your account until the issue is resolved in accordance with these Terms and Conditions.`,

        `For high-volume accounts, including business accounts, Cabbagino may, at its discretion, place a reserve against the funds in Your account if we believe that there may be a high level of risk associated with Your account. If Your account is subject to reservation, we will provide notice to You stating the conditions of the reserve. Conditions may require that a certain percentage of the amounts deposited in Your account be held for a specified period of time or that a certain amount of money be held in reserve, or that other measures be taken in the opinion of Cabbagino to protect against risk associated with Your account. We may change the terms of the reserve at any time by notifying You of new details.`,

    ],

    'Fees and charges': [
        `Fees constitutes an integral part of these Conditions and shall mean any fees, rates and charges levied by Cabbagino for provision of Services. The Company may amend any Fees from time to time on its own discretion. Customer is responsible for paying any Fees according to these Terms and Conditions. In case in relation to the Customer exist some special Fees and additional agreements, it should prevail over this Conditions and general Fees in the frame and in relation to certain services, provided by Cabbagino.`,

        `The Customer expressly agrees to duly pay for any Services provided, e.g. for international payment transfers and currency conversions, withdrawals or amendments of payment transactions, searching and preparing printouts and documents, for performing any outstanding services for the Customer by Cabbagino and sending reminders. Customer expressly agrees to pay all the applicable Fees;`,

        `In case the Transaction initiated by the Customer requires a currency exchange, the current exchange rate provided by the liquidity provider will be applied;`,

        `The Customer agrees that any Fees that are due by the Customer to Cabbagino shall be withdrawn by Cabbagino directly from the Customer’s Account at any time, including but not limited to all the losses resulting from Your unauthorized actions;`,

        `Cabbagino is entitled to amend the Fee at our own discretion;`,

        `Changes to exchange rates shall come into effect immediately without notice and the Customer shall not have the right to object to such a change.`,

        `You are responsible for determining which taxes apply to payments You make or receive as well as for collecting, reporting and transferring the correct tax to the appropriate tax authority. Cabbagino is not responsible for determining whether taxes apply to Your transaction or for collection, reporting or transfer of any taxes arising from any transaction.`,

        `If, for any reason, You have an overdue debt to Cabbagino, we will attempt to recover it from Your funding source or Cabbagino account balance. If Cabbagino is unable to return the funds, we will endeavor to contact You. Cabbagino may receive funds from any financing source or take other legal action to obtain any amounts that are more than fourteen (14) days overdue, within the limits permitted by applicable law.`,

        `Security Interest. To ensure Your compliance with these Terms and Conditions, You provide Cabbagino with a lien and security interest for Your account as well as agree to fulfill any additional documentation to improve these company rights.`,

        `Money held within Cabbagino will not lead to any any interest gains and Cabbagino does not pay interest on any balances in Customer’s Account.`,

    ],

    'Account and transfer errors': [
        `Subject to our compliance with applicable law, in the event of an error, You authorize us to make appropriate corrections by debiting or crediting Your Cabbagino account, or debiting or crediting Your primary or alternate funding source, as applicable.`,

        `If Your account history reftects transfers You have not made, You must contact us immediately via <a href="mailto:support@cabbagino.com">support@cabbagino.com</a>. If You do not contact us within 60 (sixty) days from the date the unauthorized transfer was made, You will not be able to return the money You lost after sixty (60) days, in case we can prove that we could have stopped the violator by learning the situation in time. `,

    ],

    'Powers of officers and employees': [
        `If You are a business entity, You agree that all officers, employees, agents, representatives and other entities with access to Your username and / or password will be legally empowered to use the Services on Your behalf. You are responsible for all actions taken by current and former officers, employees, agents, representatives and other entities who access the Services by means of Your username and password, regardless of whether they are authorized by You.`,

        `YOU ASSUME THE RISK FOR AN INCORRECT, FRADULENT OR IRREGULAR USE OF YOUR PASSWORD. WE ARE ENTITLED TO RELY ON THE AUTHENTICITY AND AUTHORITY OF ALL INSTRUCTIONS FROM OUR PERSON RECEIVED AS A PASSWORD ACCOMPANYING AS WELL AS FUNCTION IN ACCORDANCE WITH THEM`,
    ],
    'Privacy Policy and data processing': [
        `Protecting Your privacy is very important to us. Please read our Privacy Policy to better understand our conscientiousness in maintaining Your security and using and disclosing Your information.`,

        `If You receive information about another Cabbagino user through our services, You must keep it confidential and use it for business purposes only. You may not disclose or distribute Cabbagino user information to a third party or use this information for marketing purposes, if only You will not get the explicit consent of the user to do so.`,

        `The Customer agrees that Cabbagino as Data Processor shall process personal and other data in accordance with applicable laws and regulations. Cabbagino shall process the personal data of the Customer and those of the Customer's representatives, authorized persons, representatives, beneficial owners and other third parties associated with the Customer who have consented to such processing. Data processing includes the collection, registration, entry, storage, organization, modification, use, transfer and disclosure of data, blocking or deletion thereof.`,

        `If You believe that any registration information regarding Your Cabbagino account, the PIN or Your mobile device containing the Cabbagino account has been lost or stolen, or if Your account history reflects transfers You have not made, You have to contact us immediately <a href="mailto:support@cabbagino.com">support@cabbagino.com</a>.`,

    ],
    'Restricted activities on the account': {
        header: `In connection with Your use of our website, account or services, or within Your interactions with Cabbagino, a user or a third party, You agree to avoid the following:`,
        options: [
            `violation of these Terms and Conditions, the card processing agreement or any other agreement You have entered into with Cabbagino (including the privacy policy);`,

            `violation of any law, statute, regulation or act;`,

            `making payments from or to sanctioned countries and territories in accordance with the laws, regulations and internal policies;`,

            `infringement of copyright, patent rights, trademarks, trade secrets or other intellectual property rights of Cabbagino or any third party, as well as public or privacy rights;`,

            `insults, slander, threats or harassment;`,

            `providing false, inaccurate or misleading personal or business information;`,

            `creating more than one Cabbagino account for Yourself, inter alia, using a name other than Your name, temporary email addresses or phone numbers, or providing any other fraudulent personal or business information;`,

            `refusal to participate in an investigation, confirm Your identity or provide any necessary personal information;`,

            `attempts to "double pay" in a dispute by receiving funds from both Cabbagino and the bank or credit card issuer for the same transaction;`,

            `using anonymous proxy servers;`,

            `managing an account associated with another account that has been involved in prohibited activities;`,

            `managing or owning more than one business account without the permission of Cabbagino;`,

            `conducting Your business or using the Services in a way that may lead to complaints, disputes, claims, cancellations, chargebacks, fees, penalties and other liability to Cabbagino, the user, a third party or You;`,

            `using the services to carry out transactions in order to obtain rewards, benefits, miles, points, etc. by means of Your credit card, debit card or bank account;`,

            `using Your account or the Services in a manner that Cabbagino, Visa, MasterCard, American Express reasonably consider to be an abuse of the credit card system or a violation of credit card association rules;`,

            `maintaining a negative balance in Your Cabbagino account;`,

            `giving Yourself a cash advance from Your credit card (or helping others to do so);`,

            `sending unsolicited email to a user, using the Services to collect payment for sending, or helping to send unsolicited email to third parties;`,

            `taking any action that places an unreasonable or disproportionate load on our infrastructure;`,

            `facilitating any viruses, Trojans, worms or other elements of computer programming that may damage, interfere with, secretly intercept or expropriate any system, data or information;`,

            `using any robot, "spider", other automatic device or manual process to monitor or copy our website without our prior written permission;`,

            `using any device, software or procedure to bypass our HTTP headers to block robots or interfere with our website or Services;`,

            `taking any action that could lead to the loss of any services from our Internet service providers, payment systems or other suppliers;`,

            `using the Services to verify credit card behavior;`,

            `You agree that You will not use the Services to violate any laws, regulations, ordinances or rules regarding:`,

            'counterfeit goods;',

            `drugs, steroids, certain substances or products that pose a threat to the safety of consumers, drug accessories;`,

            `items that encourage, stimulate, facilitate or instruct others to engage in illegal activities;`,

            `items promoting hatred, violence, racial intolerance or financial crimes;`,

            `items that are considered obscene;`,

            `items that infringe any copyright, trademark, advertising, privacy or any other proprietary rights under the laws of any jurisdiction;`,

            `certain sexually oriented materials or services;`,

            `ammunition, firearms and certain parts or accessories thereof, as well as certain edged weapons in accordance with the current legislation;`,

            'revealing personal information of third parties in violation of applicable law,',

            `supporting pyramid schemes, matrix programs, other "easy money" schemes or multi-level marketing programs without a viable product / service,`,

            `related to the purchase of annuities or lottery contracts, reservation systems, offshore banking or credit card debt financing or refinancing transactions,`,

            `intended to sell certain goods before the seller can control or own them,`,

            `used by payment processors to collect payments on behalf of merchants,`,

            `associated with the sale of traveler’s checks or money orders,`,

            `involving currency exchange, check cashing, or digital currencies such as bitcoins`,

            `providing certain services for credit recovery or debt settlement,`,

            `imply the sale of products or services with a high likelihood of fraud in the opinion of government agencies,`,

            `otherwise related to illegal activity, pornography, pornographic material, or other objectionable content or activity,`,

            'tobacco products selling,',

            'prescription drugs and devices,',

            `skill games (whether or not they are legally defined as a lottery) and lotteries, unless the operator has received the prior approval of Cabbagino and the operator and customers are located exclusively in jurisdictions where such activities are permitted by law,`,

            `providing certain services for credit recovery or debt settlement,`,
        ],
    },
    'Compliance with the law': [
        `You are solely responsible for ensuring that Your use of the Services complies with applicable local laws and regulations.`,

        `You are responsible for all cancellations, chargebacks, claims, fees, penalties, and other obligations incurred by Cabbagino, a Cabbagino user or a third party caused by or arising from Your breach of these Terms and Conditions and / or Your use of the Services. You agree to indemnify Cabbagino, the user or a third party, any such liability.`,

        `You are responsible for the accuracy of all payments sent using the Services, including but not limited to the accuracy of the amount paid and the recipient’s details. We, in turn will not be responsible for inaccurate payments, including, but not limited to sending the wrong amount of money or using incorrect recipient data.`,

        `If we have reason to believe that You have participated in any prohibited activity, committed excessive or unexplained transactions, violated any part of these Terms and Conditions, or provided any incorrect information, we may take various actions to protect Cabbagino, a user of the Services, a third party or You from cancellations, chargebacks, claims, fees, fines, Cabbagino penalties and any other liability. Actions we may take include, but are not limited to the following ones:`,

        {
            header: '',
            options: [
                `we may terminate, suspend or restrict Your access to Your account or the Services (for example, restrict access to any of Your funding sources, as well as Your ability to send and withdraw funds, or delete financial information);`,

                `we may contact the users who sent You money, Your bank or credit card issuer, and alert other users, law enforcement agencies or third parties of Your actions;`,

                `we may update inaccurate information that You have provided to us;`,

                `we may refuse to provide You with our services in the future;`,

                `we may freeze Your funds for up to 180 days if warranted as part of our risk protection;`,

                `we can appeal to the court.`,

            ]
        },

        `Cabbagino reserves the right, in its sole discretion, to terminate provision of our services to You, restrict Your access to its website or the Services for any reason and at any time after notifying You.`
    ],
    'Applicable Law': [
        `These Terms and Conditions shall be governed in all respects by the laws of These Conditions are governed by the laws of Canada.`,

        `In carrying out any activities under these terms and Conditions, the Parties shall observe applicable laws and regulations and rules of international payment system (such as but not limited to Visa Europe, MasterCard Worldwide).`,

        `If a dispute arises between You and Cabbagino, our goal is to hear and resolve Your concerns to reasonable and fair satisfaction of both parties. If you have any claims or questions regarding our Services, please submit them by email <a href="mailto:support@cabbagino.com">support@cabbagino.com</a>.`,
    ],
    'Liability of the Company': [
        `The Company is not responsible for the actions of the persons to whom You send the transfer, or from whom You receive the transfer. In addition, the Company is not responsible in cases when:`,

        {
            options: [
                `through no fault of Cabbagino, You do not have sufficient balance and Cabbagino cannot charge the transaction from Your linked credit card for reasons including, but not limited to, the exceeding of the applicable credit limit;`,

                `the funds in Your account are subject to legal proceedings or other obligation restricting their use;`,

                'the   Services   were   not   functioning   properly   and   You   knew   about   the\n' +
                'breakdown when You started transferring funds;',

                `the Services were not functioning properly and You knew about the breakdown when You started transferring funds;`,

                `the failure occurred as a result of a malfunction on the part of the financial institution that issued the credit or debit card associated with Your account, or Cabbagino cannot access the linked credit card for any reason not attributable to Cabbagino;`,

                `You have provided inaccurate or incomplete information about the transfer;`,

                `the transfer appears suspicious, fraudulent or unauthorized, and Cabbagino cannot confirm that it is legal; or if the account of any of the users involved in the transfer is being investigated by Cabbagino or if the transfer is or appears to be prohibited by any applicable law or regulation.`,
            ]
        },

        `Unless otherwise prohibited by law, You assume full responsibility for Your use of our Services and any other services provided under these Terms and Conditions, and assume the risks. To the fullest extent possible under applicable law, all such statements, warranties and conditions are not recognized, including but not limited to any implied warranties of merchantability, fitness for a particular purpose, title, infringement of intellectual property rights, or other terms that might otherwise be implied by law, common law or equity. Cabbagino does not guarantee that the services will be uninterrupted or error-free, that defects will be corrected, or that the services or servers that process information are free of viruses, bugs, or other harmful components.`,

        `Unless otherwise expressly provided in these Terms and Conditions and to the extent permitted by applicable law, Cabbagino’s aggregate liability to You for any claim or loss arising out of or related to Your use of the Services shall not exceed the amount of fees You paid to Cabbagino for using Services or USD 1.00.`,

        `The services are provided on an ‘as is’, ‘as available’ and ‘unfavorable’ basis, without any representations, warranties or conditions of any kind, expressed, implied or statutory, including, without limitation, any warranties regarding the use or operation of services or information, content or other material related to the Services provided.`,

        `These limitations of liability also apply to:`,

        {
            options: [
                `everything related to services, any app or content available through it;`,

                `claims for violation of the contract, warranty, conditions, strict liability, negligence or other violations of a legal nature within the limits permitted by applicable law;`,
            ]
        },

        `These limitations of liability also apply even if the repair, replacement or return for service does not fully compensate You for any damages, or Cabbagino knew or should have known of the potential for damages. These limitations of liability will apply to the maximum extent permitted by applicable law, even if any remedy fails to achieve its primary purpose. Some states or other jurisdictions do not allow limitation of liability, so the above limitations may not apply to You. You may also have other rights that vary from state to state and jurisdiction in jurisdiction.`,
    ],
    'Remedies': [
        `You agree to indemnify Cabbagino and keep Cabbagino indemnified against all losses, taxes, expenses, costs and liabilities whatsoever (present, future, contingent or otherwise, and including reasonable legal fees), even if all or some of us have been warned of the possibility of such damages and even if any means legal remedy does not achieve its main purpose, which may be suffered or incurred by Cabbagino as a result of or in connection with any your breach of these Terms and Conditions by the Customer and/or as a result of illegal actions performed by You using our services.`,

        `With respect to the Services, Cabbagino will make reasonable efforts to ensure that all electronic debit and credit requests related to bank accounts, credit cards and other funding or withdrawals are processed in a timely manner, however Cabbagino makes no representations or warranties as to the amount of time required to completion of processing as Cabbagino is subject to many factors beyond its control, such as delays in the banking system or international postal services.`,

    ],
    'Compensation': {
        header: `You agree to defend, indemnify and hold harmless Cabbagino and its founders, partners, officers, directors and employees from any claims (including any damages, costs and attorneys’ fees arising therefrom) that were made or incurred by a third party because of or due to Your violation of these Terms and Conditions and / or Your use of the Services.`
    },
    'Assumption of Rights': {
        header: `If Cabbagino pays You funds for a claim, cancellation or chargeback that You submit to us for the payee, You agree that Cabbagino assumes Your rights against the payee and third parties associated with the payment and may continue those rights directly or on Your behalf at Cabbagino’s discretion.`
    },
    'Disengagement of the Company': {
        header: `If You have a dispute with one or more users of the Services regarding payment, Cabbagino is not responsible for any such dispute, and You thereby release Cabbagino (as well as our officers, directors, agents, partners and employees) from claims and damages (actual and consequential) of any kind and nature arising from or in any way connected with such disputes.`
    },
    'Change of terms and conditions': [
        `We may change these Terms and Conditions at any time by posting a revised version on our website. The revised version will be effective upon posting unless it contains significant changes. If we make significant changes to these Terms and Conditions, we will notify You  by posting it on our website (including, but not limited to data storage policies and privacy and security policies), or by sending it to You electronically or by mail if we have the email address or physical address You entered on Your account 10 days before the changes take effect. `,

        `By using the Services after the publication of the new Terms and Conditions, You agree to the amended Terms and Conditions.`,

        `Cabbagino reserves the right to use its website to inform You about any changes in these Terms and Conditions and the posting of a notice on the website shall be deemed a valid notification of such changes to You. Please, make sure, that You review our website where relevant information may be published.`,
    ],

    'Notices': [
        `Notices shall be delivered in accordance with these Terms and Conditions.`,

        `Cabbagino reserves the right to notify You via webside and this shall be deemed a valid notice.`,

        `Cabbagino reserves the right to notify you electronically or via mail. Such notice will be deemed to have been received by You within twenty-four (24) hours of the date it is posted on our website or sent to You by email, unless we we receive the notification that the email has not been delivered. If the notification is sent by mail, we will assume that it was received by You seven (7) business days after it was sent. We may also provide a notice when logging into Your Cabbagino account`,

        `If You disagree or withdraw Your consent to receive email notifications from us, we reserve the right to close Your accounts.`,

        `Cabbagino notices must be mailed to 319 W HASTINGS STREET, #400 VANCOUVER, BC, CANADA V6B1H6.`,

        `By providing Cabbagino with a phone number (including mobile / cell phone), You agree to receive auto-dialed messages and pre-recorded calls from Cabbagino to that number if necessary. We will never call You for promotional purposes.`,

    ],
    'Force majeure': {
        header: `We are not responsible for any delays or disruptions in the operation, the provision of services or the delivery of materials, as well as for any damage incurred by You due to such delays or disruptions, directly or indirectly caused by or in any way arising from or related to natural disasters, actions of enemy forces, riots, strikes, actions of government agencies, refusal of authorities, telecommunications or other suppliers, delays in the provision or shortage of raw materials, breakdown or destruction of any system or equipment, or any other cause or reasons beyond our control, regardless of whether they are similar to those listed above.`,
    },
    'Miscellanea': [
        `The section headings in these terms and Conditions are for convenience only and should not be construed as parts, provisions or interpretations of these Terms and Conditions.`,

        `You may not transfer or assign any rights or obligations under these Terms and Conditions without Cabbagino’s prior written consent. Cabbagino reserves the right to transfer or assign any right or obligation under these Terms and Conditions at any time. Subject to the foregoing, these Conditions will be fully binding upon, inure to the benefit of and be enforceable by the parties hereto and their respective successors and assigns. `,

        `Our failure to act in response to a violation by You or others does not preclude us from responding to that violation or subsequent or similar violations.`,

        `Cabbagino websites may host offers from third parties and may allow for search of products. Cabbagino does not warrant that product descriptions, prices, search results, user ratings and reviews, or any other content on Cabbagino’s websites are accurate, complete, reliable or up-to-date.`,

        `If You are a legal entity, You claim that You employ the appropriate authority to conduct business in Your jurisdiction of registration, and that Your employees, officers, representatives and other agents with access to the Services are duly authorized to make use of the Services and are legal the grounds for contacting You with these Terms and Conditions and all transactions conducted under Your username and password.`,

        `All information shall be received in English only and English shall be the language used for purposes of these Terms and Conditions and for further communication between You and Cabbagino.`,

        `Retention of legal force. In the event of termination of provision of the Services, these Terms and Conditions continuing by nature shall remain in effect upon termination, including but not limited to disclaimers and limitations of liability.`,
    ]
}
