import {BrowserRouter as Router, Switch, Route, NavLink, Redirect, useLocation} from 'react-router-dom';
import ForPerson from '../pages/ForPerson';
import ForBusiness from '../pages/ForBusiness';
import AboutUs from '../pages/AboutUs';
import Rules from '../pages/Rules';
import Policy from '../pages/Policy';
import Security from '../pages/Security';
import TermsPolicy from "../pages/TermsPolicy";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Login from "../pages/Login";
import Registration from "../pages/Registration";

const PageSwitch = () => {
    return (
        <Switch>
            <Route exact path="/">
                <ForPerson/>
            </Route>
            <Route path="/for-business">
                <ForBusiness/>
            </Route>
            <Route path="/about-us">
                <AboutUs/>
            </Route>
            <Route path="/rules">
                <Rules/>
            </Route>
            <Route path="/terms-and-conditions">
                <TermsPolicy/>
            </Route>
            <Route path="/privacy">
                <PrivacyPolicy/>
            </Route>
            <Route path="/security">
                <Security/>
            </Route>
            <Route path="/login">
                <Login />
            </Route>
            <Route path="/registration">
                <Registration />
            </Route>
        </Switch>
    )
}

export default PageSwitch;
