import en from './languages/en.svg';
import ru from './languages/ru.svg';
import uk from './languages/uk.svg';
import logo from './logo.svg';
import logoInvert from './logo-invert.svg';
import arrow from './arrow.svg';
import paymentSystems from './payment-systems.svg';
import mastercard from './mastercard.svg';
import amex from './amex.svg';
import visa from './visa.svg';
import mir from './mir.svg';
import jcb from './jcb.svg';
import hero from './hero.svg';
import hero2 from './hero-2.svg';
import heroBg from './hero-bg.svg';
import iconBox1 from './icon-box-1.png';
import iconBox2 from './icon-box-2.svg';
import iconBox3 from './icon-box-3.png';
import iconBox4 from './icon-box-4.svg';
import iconBox5 from './icon-box-5.svg';
import iconBox21 from './icon-box-2-1.svg';
import iconBox22 from './icon-box-2-2.svg';
import iconBox23 from './icon-box-2-3.svg';
import iconBox24 from './icon-box-2-4.svg';
import iconBox31 from './icon-box-3-1.svg';
import iconBox32 from './icon-box-3-2.svg';
import iconBox33 from './icon-box-3-3.svg';
import iconBox34 from './icon-box-3-4.svg';
import iconBox35 from './icon-box-3-5.svg';
import iconBox36 from './icon-box-3-6.svg';
import tilesIcon1 from './tiles-icon-1.svg';
import tilesIcon2 from './tiles-icon-2.svg';
import tilesIcon3 from './tiles-icon-3.svg';
import tilesIcon21 from './tiles-icon-2-1.svg';
import tilesIcon22 from './tiles-icon-2-2.svg';
import tilesIcon23 from './tiles-icon-2-3.svg';
import tilesIcon31 from './tiles-icon-3-1.svg';
import tilesIcon32 from './tiles-icon-3-2.svg';
import tilesIcon33 from './tiles-icon-3-3.svg';
import arrowRight from './arrow-right.svg';
import arrowRightGray from './arrow-right-gray.svg';
import chessSection1 from './chess-section-1.svg';
import chessSection2 from './chess-section-2.svg';
import chessSection21 from './chess-section-2-1.svg';
import chessSection22 from './chess-section-2-2.svg';
import checkMark from './check-mark.svg';
import burger from './burger.svg';
import exchange from './crypto-exchange.png';
import exchange2 from './exchange-illustration.svg';
const icons = {
    en,
    ru,
    uk,
    logo,
    logoInvert,
    arrow,
    paymentSystems,
    mastercard,
    amex,
    visa,
    mir,
    jcb,
    hero,
    hero2,
    heroBg,
    iconBox1,
    iconBox2,
    iconBox3,
    iconBox4,
    iconBox5,
    iconBox21,
    iconBox22,
    iconBox23,
    iconBox24,
    iconBox31,
    iconBox32,
    iconBox33,
    iconBox34,
    iconBox35,
    iconBox36,
    arrowRight,
    arrowRightGray,
    tilesIcon1,
    tilesIcon2,
    tilesIcon3,
    tilesIcon21,
    tilesIcon22,
    tilesIcon23,
    tilesIcon31,
    tilesIcon32,
    tilesIcon33,
    chessSection1,
    chessSection2,
    chessSection21,
    chessSection22,
    checkMark,
    burger,
    exchange,
    exchange2
}

export default icons
