import React from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';

import LangSwitcher from './LangSwitcher';

import icons from '../assets';

const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="footer">
      <div className="container container_custom">
        <div className="footer__nav">
          <img className="footer__logo" src={icons.logoInvert} />
          <NavLink to="/">{t('For individuals')}</NavLink>
          <NavLink to="/for-business">{t('For business')}</NavLink>
          <NavLink to="/about-us">{t('About us')}</NavLink>
          <a href="mailto:support@cabbagino.com">{t('Contact us')}</a>
          <a
            className="footer__nav-item_enter"
            href="/login"
          >
            {t('Sign in')}
          </a>
          <a
            className="footer__nav-item_registration footer__nav-item_active"
            href="https://wallet.cabbagino.com/registration"
            target="_blank"
          >
            {t('Sign up')}
          </a>
          {/*<LangSwitcher />*/}
        </div>
        <div className="footer__content">
          <div className="footer__content-left">
            <p className="footer__left-text">{t('As part of consumer protection: CABBAGINO PAYMENTS LTD., the holder of the stored data of Cabbagino, does not require approval from the Financial Transactions and Reports Analysis Centre of Canada (FINTRAC). Users are advised to carefully read the terms and conditions of use.')}</p>
            <span className="copyright">{t('Cabbagino Payments LTD © 2020. All rights reserved.')}</span>
          </div>
          <div className="footer__content-right">
            <p className="footer__right-text">{t('The Cabbagino platform is operated by CABBAGINO PAYMENTS LTD., registered Money services business in Canada. CABBAGINO PAYMENTS LTD. is operated in full compliance with the rules of The Financial Transactions and Reports Analysis Centre of Canada (FINTRAC). CABBAGINO PAYMENTS LTD. bears the status of Foreign exchange dealing, Money transferring and Dealing in virtual currencies operator.')}</p>
            <p className="footer__right-text">{t('Transactions using Bank Cards are processed in strict accordance with the PCI DSS information security standard.')}</p>
            <ul className="footer__right-menu">
              <li className="footer__right-menu__item">
                <NavLink to="/terms-and-conditions">{t('Terms and Conditions')}</NavLink>
              </li>
              <li className="footer__right-menu__item">
                <NavLink to="/privacy">{t('Privacy Policy')}</NavLink>
              </li>
              <li className="footer__right-menu__item">
                <NavLink to="/terms-and-conditions">{t('Security policy')}</NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer__partners">
          <div>
            <img src={icons.paymentSystems} />
          </div>
          <div>
            <a href="#"><img src={icons.mastercard} /></a>
            <a href="#"><img src={icons.amex} /></a>
            <a href="#"><img src={icons.visa} /></a>
            <a href="#"><img src={icons.jcb} /></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
