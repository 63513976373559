import { BrowserRouter as Router, Switch, Route, NavLink, useLocation } from 'react-router-dom';
import LangSwitcher from './LangSwitcher';
import { useTranslation } from 'react-i18next';
import Drawer from './Drawer';

import icons from '../assets';

const Header = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  return (
    <header className={`header ${pathname === "/" && "header-personal"}`}>
      <div className="container container_custom">
        <div className="header__wrap">
          <NavLink to="/">
            <img src={icons.logo} width='216' height='45' />
          </NavLink>
          <div className="header__part header__part_middle">
            <NavLink exact className="header__item" to="/">{t('For individuals')}</NavLink>
            <NavLink className="header__item header__item_blue" to="/for-business">{t('For business')}</NavLink>
          </div>
          <div className="header__part">
            <NavLink className="desctop-only header__item header__item_light" activeClassName="header__item_active" to="/about-us">{t('About us')}</NavLink>
            <a className="desctop-only header__item header__item_light" href="mailto:support@cabbagino.com">{t('Contact us')}</a>
            {/*<LangSwitcher className="desctop-only" />*/}
            <Drawer />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header;
