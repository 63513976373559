import React from 'react';
import {useTranslation} from 'react-i18next';

const Security = () => {
  const {t} = useTranslation();
  return (
    <></>
  );
}

export default Security;
