import React from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import icons from '../assets';
import chessSection21 from "../assets/chess-section-2-1.svg";
import tilesIcon33 from "../assets/tiles-icon-3-3.svg";
import tilesIcon32 from "../assets/tiles-icon-3-2.svg";

const ForPerson = () => {
  const {t} = useTranslation();
  return (
    <>
      <div className="hero">
        <div className="container container_custom">
          <div className="hero__top">
            <p className="hero__top-text">{t('INTERNATIONAL PAYMENTS')}</p>
            <div>
              <a className="hero__top-link" href="/login">{t('Sign in')}</a>
              <a className="btn btn_small btn_green" href="/registration">{t('Sign up')}</a>
            </div>
          </div>
          <div className="hero__content">
            <div className="hero__part-text">
              <h1 className="hero__title">{t('Your e-wallet')}</h1>
              <h3 className="hero__description">{t('Safe money transfers from any device and anywhere in the world')}</h3>
              <a className="btn btn_dark hero__btn" href="https://wallet.cabbagino.com/registration" target="_blank">
                {t('Create an account for free')}
                <img src={icons.arrowRight} className="btn__icon" />
              </a>
            </div>
            <img src={icons.hero} className="hero__part-image" />
          </div>
        </div>
      </div>
      <div className="banner">
        <div className="banner__wrap">
          <div className="container container_custom">
            <div className="banner__content banner__content_green">
              <h2 className="banner__title">{t('Сabbagino')}</h2>
              <p className="banner__text" dangerouslySetInnerHTML={{__html: t('<span className="banner__text_strong">Fast, reliable and easy</span> money management perfect <span className="banner__text_strong">for online payments</span>')}}>
                </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container container_custom">
        <div className="icon-box icon-box_w-space">
          <h3 className="icon-box__title">{t('Universal payment system')}</h3>
          <div className="icon-box__wrap">
            <div className="icon-box__item">
              <img src={icons.iconBox1} className="icon-box__icon" />
              <p className="icon-box__text">{t('Online purchases and money transfer to your account')}</p>
            </div>
            <div className="icon-box__item">
              <img src={icons.iconBox2} className="icon-box__icon" />
              <p className="icon-box__text">{t('Safe storage of funds')}</p>
            </div>
            <div className="icon-box__item">
              <img src={icons.iconBox3} className="icon-box__icon" />
              <p className="icon-box__text">{t('Instant account replenishment')}</p>
            </div>
            <div className="icon-box__item">
              <img src={icons.iconBox4} className="icon-box__icon" />
              <p className="icon-box__text">{t('Easy way to deposit and withdraw funds')}</p>
            </div>
            <div className="icon-box__item">
              <img src={icons.iconBox5} className="icon-box__icon" />
              <p className="icon-box__text">{t('Various payment options')}</p>
            </div>
          </div>
          <div className="tiles__btn-wrap">
            <a
              className="btn btn_dark"
              href="https://wallet.cabbagino.com/registration"
              target="_blank"
            >
              {t('Create an account for free')}
              <img src={icons.arrowRight} className="btn__icon" />
            </a>
          </div>
        </div>
      </div>
      <div className="tiles">
        <div className="container container_custom">
          <div className="tiles__top">
            <h3 className="tiles__title">{t('How it works?')}</h3>
            <p className="tiles__text"
              dangerouslySetInnerHTML={{__html: t('To make purchases and send money, you just need a smartphone supporting <b>Android</b> or <b>iOS</b> to download an app <b>Cabbagino</b>.')}}
            ></p>
          </div>
          <div className="tiles__wrap">
            <div className="tiles__item">
              <img src={icons.tilesIcon1} className="tiles__icon" width="120" height="120" />
              <h4 className="tiles__item-title">{t('Create an account')}</h4>
              <p className="tiles__item-text">{t('Top up your Cabbagino account and start paying for your online purchases')}</p>
            </div>
            <div className="tiles__item">
              <img src={icons.tilesIcon2} className="tiles__icon" width="120" height="120" />
              <h4 className="tiles__item-title">{t('Make payments')}</h4>
              <p className="tiles__item-text">{t('Manage your funds by means of simple and secure payment options')}</p>

            </div>
            <div className="tiles__item">
              <img src={icons.tilesIcon3} className="tiles__icon" width="120" height="120" />
              <h4 className="tiles__item-title">{t('Withdraw funds')}</h4>
              <p className="tiles__item-text">{t('You can transfer money from our wallet to any bank account')}</p>
            </div>
          </div>
          <div className="tiles__btn-wrap">
            <a
              className="btn btn_dark"
              href="https://wallet.cabbagino.com/registration"
              target="_blank"
            >
              {t('Create an account for free')}
              <img src={icons.arrowRight} className="btn__icon" />
            </a>
          </div>
        </div>
      </div>
      <div className="chess-section">
        <div className="container container_custom">
          <div className="chess-section__wrap chess-section__wrap_invert mb-5">
              <img src={icons.exchange2} className="chess-section__icon text-center"/>

            <div className="chess-section__content">
              <h3 className="chess-section__title">{t('Exchange cryptocurrencies, trade with confidence')}</h3>
              <p className="chess-section__text" dangerouslySetInnerHTML={{__html: t('It goes without saying, but confidence is essential to trading or anything individuals set out to do.')}} />
            </div>
          </div>
        </div>
        <div className="container container_custom">
          <div className="chess-section__wrap">
            <img src={icons.chessSection1} className="chess-section__icon" />
            <div className="chess-section__content">
              <h3 className="chess-section__title">{t('Transfer funds in an instant')}</h3>
              <p className="chess-section__text"
                dangerouslySetInnerHTML={{__html: t('Сabbagino allows <b>to send and receive money</b> in various currencies in just a few clicks.')}}
              />
              <p className="chess-section__text"
                dangerouslySetInnerHTML={{__html: t('With Сabbagino, you can <b>send money as a gift, make a transfer abroad or any online purchase.</b>')}}
              />
            </div>
          </div>
        </div>

        <div className="container container_custom">
          <div className="chess-section__wrap chess-section__wrap_invert">
            <img src={icons.chessSection2} className="chess-section__icon" />
            <div className="chess-section__content">
              <h3 className="chess-section__title">{t('Security and protection of funds')}</h3>
              <p className="chess-section__text" dangerouslySetInnerHTML={{__html: t('Our main purpose is <b>to ensure the security and safeguarding of your personal data</b>')}}></p>
              <p className="chess-section__text" dangerouslySetInnerHTML={{__html: t('Сabbagino uses innovative technologies to guarantee security and anti-fraud protection while offering the customers a quick <b>safe method of money transfer</b> and online purchases')}}></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ForPerson;
