import Policy from "./Policy";
import React from "react";
import {useTranslation} from "react-i18next";
import Privacy from "../data/Privacy";

const PrivacyPolicy = () => {
    const {t} = useTranslation();
    return (
        <Policy
            header={(
                <>
                    <h3 className="policy__title">{t('Privacy Policy')}</h3>
                    <p className="policy__text">{t('This Privacy Policy informs You about the Company`s policies on the collection, use, and\n' +
                        'disclosure of Your personal data when You use the Company`s service. The Company fully\n' +
                        'complies with all laws and regulations as the security of the personal data of the Company\'s\n' +
                        'customers   is   of   primary   concern   to   the   Company.   The   company   collects   information   to\n' +
                        'ensure the safe, correct, and lawful provision of services to all its customers.')}</p>
                </>
            )}
            data={Privacy}
        />
    )
};

export default PrivacyPolicy;