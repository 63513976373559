import React from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import icons from '../assets';
import {
    FormGroup,
    Form,
    Label,
    Input,
    Button, Card, CardBody, Row, Col
} from "reactstrap";

const Login = () => {
  const {t} = useTranslation();
  return (
    <>
      <div className="hero hero_bg">
        <div className="container container_custom">
            <Row>
                <Col class="col-md-auto">
                    <h5 className={'text-center mb-3 mt-3'}>Sign In</h5>
                </Col>
            </Row>
            <Row>
                <Col className="col col-lg-3 hidden-xs" />
                <Col class="col-md-auto">
                    <Card>
                        <CardBody>
                        <Form className="form">
                            <FormGroup>
                                <Label for="exampleEmail">Username</Label>
                                <Input
                                    type="email"
                                    name="email"
                                    id="exampleEmail"
                                    placeholder="Enter your e-mail"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="examplePassword">Password</Label>
                                <Input
                                    type="password"
                                    name="password"
                                    id="examplePassword"
                                    placeholder="********"
                                />
                            </FormGroup>
                            <div className={'d-flex justify-content-center'}>
                                <buttom className={'btn btn_green'}>Sign In</buttom>
                            </div>
                        </Form>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="col col-lg-3 hidden-xs"/>
            </Row>
        </div>
      </div>
    </>
  );
}

export default Login;
