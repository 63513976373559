import React from 'react';
import { useTranslation } from 'react-i18next';
import icons from '../assets';

const LangSwitcher = ({ className }) => {
  const { t, i18n } = useTranslation();
  const langSwitcherRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const handleClickOutside = (e) => {
      if (langSwitcherRef.current && !langSwitcherRef.current.contains(e.target)) setOpen(false);
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [langSwitcherRef]);

  return (
    <div className={`lang-switcher ${className ? className : ''}`} onClick={() => setOpen(!open)} ref={langSwitcherRef}>
      <img className="lang-switcher__icon" src={icons[i18n.language]} width="24" height='24' />
      <img className="lang-switcher__arrow" src={icons.arrow} width="10" height="24" />
      {Object.keys(i18n.options.resources).length && open ?
        <ul className="lang-switcher__list">
          {Object.keys(i18n.options.resources).map(item => {
            return (
              <li className="lang-switcher__item" onClick={() => i18n.changeLanguage(item)}>
                <img className="lang-switcher__item-icon" src={icons[item]} />
              </li>
            )
          })}
        </ul>
        : null}
    </div>
  )
}

export default LangSwitcher;
