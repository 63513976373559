import React from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import icons from '../assets';

const ForBusiness = () => {
  const {t} = useTranslation();
  return (
    <>
      <div className="hero hero_invert">
        <div className="container container_custom">
          <div className="hero__top">
            <p className="hero__top-text">{t('INTERNATIONAL PAYMENTS')}</p>
            <div>
              <a className="hero__top-link" href="/login">{t('Sign in')}</a>
              <a className="btn btn_small btn_blue" href="/registration">{t('Sign up')}</a>
            </div>
          </div>
          <div className="hero__content">
            <img src={icons.hero2} className="hero__part-image" />
            <div className="hero__part-text">
              <h1 className="hero__title">{t('New opportunities for your business')}</h1>
              <h3 className="hero__description">{t('Simple and affordable online payments')}</h3>
              <a
                className="btn btn_dark hero__btn"
                href="https://wallet.cabbagino.com/registration"
                target="_blank"
              >
                {t('Register a business account')}
                <img src={icons.arrowRight} className="btn__icon" width="12" height="12"/>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="banner">
        <div className="banner__wrap">
          <div className="container container_custom">
            <div className="banner__content banner__content_blue">
              <h2 className="banner__title">{t('Сabbagino')}</h2>
              <p className="banner__text">{t('Modern payment solutions meeting all the requirements of your business')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container container_custom">
        <div className="icon-box icon-box_w-space">
          <h3 className="icon-box__title">{t('Leader in digital payments')}</h3>
          <p className="icon-box__description">{t('Solutions that suit all types of business')}</p>
          <div className="icon-box__wrap">
            <div className="icon-box__item">
              <img src={icons.iconBox21} className="icon-box__icon" width="89" height="74" />
              <p className="icon-box__text">{t('Multifunctional e-wallet')}</p>
            </div>
            <div className="icon-box__item">
              <img src={icons.iconBox22} className="icon-box__icon" width="89" height="74" />
              <p className="icon-box__text">{t('Quick registration and order placement')}</p>
            </div>
            <div className="icon-box__item icon-box__item_empty">
            </div>
            <div className="icon-box__item">
              <img src={icons.iconBox23} className="icon-box__icon" width="89" height="74" />
              <p className="icon-box__text">{t('Convenient and flexible integration process')}</p>
            </div>
            <div className="icon-box__item">
              <img src={icons.iconBox24} className="icon-box__icon" width="89" height="74" />
              <p className="icon-box__text">{t('Various options for money transfers')}</p>
            </div>
          </div>
          <div className="tiles__btn-wrap">
            <a
              className="btn btn_dark"
              href="https://wallet.cabbagino.com/registration"
              target="_blank"
            >
              {t('Register a business account')}
              <img src={icons.arrowRight} className="btn__icon" width="12" height="12" />
            </a>
          </div>
        </div>
      </div>
      <div className="tiles">
        <div className="container container_custom">
          <div className="tiles__top">
            <h3 className="tiles__title">{t('What do we offer?')}</h3>
            <p className="tiles__text"
              dangerouslySetInnerHTML={{__html: t('<b>Fast, reliable</b> and <b>optimized</b> payment solutions')}}
            />
          </div>
          <div className="tiles__wrap">
            <div className="tiles__item">
              <img src={icons.tilesIcon21} className="tiles__icon" width="120" height="120" />
              <h4 className="tiles__item-title">{t('Reliability and safety')}</h4>
              <p className="tiles__item-text">{t('You can accept payments and be completely positive about the confidentiality and security of your data.')}</p>
            </div>
            <div className="tiles__item">
              <img src={icons.tilesIcon22} className="tiles__icon" width="120" height="120" />
              <h4 className="tiles__item-title">{t('Easy platform integration')}</h4>
              <p className="tiles__item-text">{t('Thanks to the simple and free integration, you will be able to accept payments in more than 40 currencies')}</p>

            </div>
            <div className="tiles__item">
              <img src={icons.tilesIcon23} className="tiles__icon" width="120" height="120" />
              <h4 className="tiles__item-title">{t('Payments from anywhere in the world')}</h4>
              <p className="tiles__item-text">{t('Increase conversion and expand geography by accepting instant payments from anywhere in the world')}</p>
            </div>
          </div>
          <div className="tiles__btn-wrap">
            <a
              className="btn btn_dark"
              href="https://wallet.cabbagino.com/registration"
              target="_blank"
            >
              {t('Register a business account')}
              <img src={icons.arrowRight} className="btn__icon" />
            </a>
          </div>
        </div>
      </div>
      <div className="container container_custom">
        <div className="icon-box">
          <h3 className="icon-box__title">{t('Solutions and advantages of Сabbagino')}</h3>
          <p className="icon-box__description">{t('Improve business performance, boost conversions and stay one step ahead of competitors')}</p>
          <div className="icon-box__wrap">
            <div className="icon-box__item">
              <img src={icons.iconBox31} className="icon-box__icon" />
              <p className="icon-box__text"
                dangerouslySetInnerHTML={{__html: t('Simple <b>API integration</b>')}}
              />
            </div>
            <div className="icon-box__item">
              <img src={icons.iconBox32} className="icon-box__icon" />
              <p className="icon-box__text"
                dangerouslySetInnerHTML={{__html: t('Smart <b>monitoring system</b>')}}
              />
            </div>
            <div className="icon-box__item">
              <img src={icons.iconBox33} className="icon-box__icon" />
              <p className="icon-box__text"
                dangerouslySetInnerHTML={{__html: t('Security guaranteed by <b>PCI DSS</b>')}}
              />
            </div>
            <div className="icon-box__item">
              <img src={icons.iconBox34} className="icon-box__icon" />
              <p className="icon-box__text"
                dangerouslySetInnerHTML={{__html: t('Swift <b>support service</b> in different languages')}}
              />
            </div>
            <div className="icon-box__item">
              <img src={icons.iconBox35} className="icon-box__icon" />
              <p className="icon-box__text"
                dangerouslySetInnerHTML={{__html: t('Ability to commit <b>recurring payments</b>')}}
              />
            </div>
            <div className="icon-box__item">
              <img src={icons.iconBox36} className="icon-box__icon" />
              <p className="icon-box__text"
                dangerouslySetInnerHTML={{__html: t('User-friendly <b>interface with analytic</b> data')}}
              />
            </div>
          </div>
          <div className="tiles__btn-wrap">
            <a
              className="btn btn_dark"
              href="https://wallet.cabbagino.com/registration"
              target="_blank"
            >
              {t('Register a business account')}
              <img src={icons.arrowRight} className="btn__icon" width="12" height="12" />
            </a>
          </div>
        </div>
      </div>
      <div className="chess-section">
        <div className="container container_custom">
          <div className="chess-section__wrap">
            <img src={icons.chessSection21} className="chess-section__icon" />
            <div className="chess-section__content">
              <h3 className="chess-section__title">{t('New payment options')}</h3>
              <p className="chess-section__text"
                dangerouslySetInnerHTML={{__html: t('Instant <b>payment accepting</b> from anywhere in the world <b>in any currency</b> as well as the possibility <b>to use funds at any time of the day</b> makes Cabbagino one of the most effective tools for your online business.')}}
              />
            </div>
          </div>
        </div>
        <div className="container container_custom">
          <div className="chess-section__wrap chess-section__wrap_invert">
            <img src={icons.chessSection22} className="chess-section__icon" />
            <div className="chess-section__content">
              <h3 className="chess-section__title">{t('Affiliate program')}</h3>
              <p className="chess-section__text"
                dangerouslySetInnerHTML={{__html: t('Simple and transparent <b>way to get guaranteed profit</b> for every purchase made through Cabbagino on your recommendation. We guarantee <b>regular payments, quick access to earned commission</b> and assistance of a personal manager at all stages of cooperation.')}}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForBusiness;
