import Policy from "./Policy";
import React from "react";
import {useTranslation} from "react-i18next";
import TermsAndConditions from "../data/TermsAndConditions";

const TermsPolicy = () => {
    const {t} = useTranslation();
    return (
        <Policy
            header={(
                <>
                    <h3 className="policy__title">{t('Terms and Conditions')}</h3>
                    <p className="policy__text">{t('By using the CABBAGINO PAYMENTS LTD.`s (hereinafter referred to as ‘Company’, ‘Cabbagino’, ‘we’, ‘our’, ‘us’) services or by creating a Cabbagino Account (hereinafter - the ‘Service’ or ‘Services’), You (or hereinafter as “Customer”) are deemed consented to be bound by these Terms and Conditions.')}</p>
                    <p className="policy__text">{t('By creating a Cabbagino account, You agree that You have read, agreed and accepted all the Terms and Conditions (except for any specific terms that apply to services that You do not use), as well as our Privacy Policy (hereinafter referred to as ‘Privacy Policy’).')}</p>
                </>
            )}
            data={TermsAndConditions}
        />
    )
};

export default TermsPolicy;
