import en from './translations/en/messages.json';
import ru from './translations/ru/messages.json';

export default {
    en: {
        translation: en
    },
    ru: {
        translation: ru
    }
}