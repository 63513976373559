import React from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import icons from '../assets';
import {Card, CardBody, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";

const Registration = () => {
  const {t} = useTranslation();
  return (
      <>
          <div className="hero hero_bg">
              <div className="container container_custom">
                  <Row>
                      <Col class="col-md-auto">
                          <h5 className={'text-center mb-3 mt-3'}>Sign Up</h5>
                      </Col>
                  </Row>
                  <Row>
                      <Col className="col col-lg-3 hidden-xs" />
                      <Col class="col-md-auto">
                          <Card>
                              <CardBody>
                                  <Form className="form">
                                      <FormGroup>
                                          <Label for="name">Username</Label>
                                          <Input
                                              type="text"
                                              name="username"
                                              id="name"
                                              placeholder="Enter your nickname"
                                          />
                                      </FormGroup>
                                      <FormGroup>
                                          <Label for="email">E-mail</Label>
                                          <Input
                                              type="email"
                                              name="email"
                                              id="email"
                                              placeholder="Enter your e-mail"
                                          />
                                      </FormGroup>
                                      <FormGroup>
                                          <Label for="password">Password</Label>
                                          <Input
                                              type="password"
                                              name="password"
                                              id="password"
                                              placeholder="********"
                                          />
                                      </FormGroup>
                                      <div className={'d-flex justify-content-center'}>
                                        <buttom className={'btn btn_green text-center'}>Registration</buttom>
                                      </div>
                                  </Form>
                              </CardBody>
                          </Card>
                      </Col>
                      <Col className="col col-lg-3 hidden-xs"/>
                  </Row>
              </div>
          </div>
      </>
  );
}

export default Registration;
