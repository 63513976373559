import React from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';

import LangSwitcher from './LangSwitcher';

import icons from '../assets';

const Drawer = () => {
  const { t, i18n } = useTranslation();
  const langSwitcherRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const handleClickOutside = (e) => {
      if (langSwitcherRef.current && !langSwitcherRef.current.contains(e.target)) setOpen(false);
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [langSwitcherRef]);

  return (
    <>
      <img src={icons.burger} className="burger" onClick={() => setOpen(true)} />
      <div className={`drawer ${open ? 'drawer_open' : ''}`}>
        <div className="drawer-header">
          <NavLink className="drawer__icon mobile-only" to="/">
            <img src={icons.logo} />
          </NavLink>
          <span className="drawer__close" onClick={() => setOpen(false)}>×</span>
        </div>

        <LangSwitcher />
        <ul className="drawer-menu">
          <li className="drawer-menu__item drawer-menu__item_dark">
            <NavLink exact to="/" onClick={() => setOpen(false)}>{t('For individuals')}</NavLink>
          </li>
          <li className="drawer-menu__item drawer-menu__item_dark">
            <NavLink to="/for-business" onClick={() => setOpen(false)}>{t('For business')}</NavLink>
          </li>
          <li className="drawer-menu__divider"></li>
          <li className="drawer-menu__item">
            <NavLink to="/about-us" onClick={() => setOpen(false)}>{t('About us')}</NavLink>
          </li>
          <li className="drawer-menu__item">
            <a onClick={() => setOpen(false)} href="mailto:support@cabbagino.com">{t('Contact us')}</a>
          </li>
          <li className="drawer-menu__divider"></li>
          <li className="drawer-menu__item drawer-menu__item_dark">
            <a onClick={() => setOpen(false)} href="/login"><strong>{t('Sign in')}</strong></a>
          </li>
          <li className="drawer-menu__item drawer-menu__item_green">
            <a onClick={() => setOpen(false)} href="/registration"><strong>{t('Sign up')}</strong></a>
          </li>
          <li className="drawer-menu__divider"></li>
          <li className="drawer-menu__item drawer-menu__item_small">
            <NavLink onClick={() => setOpen(false)} to="/terms-and-conditions">{t('Terms and Conditions')}</NavLink>
          </li>
          <li className="drawer-menu__item drawer-menu__item_small">
            <NavLink onClick={() => setOpen(false)} to="/privacy">{t('Privacy Policy')}</NavLink>
          </li>
          <li className="drawer-menu__item drawer-menu__item_small">
            <NavLink onClick={() => setOpen(false)} to="/terms-and-conditions">{t('Security policy')}</NavLink>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Drawer;
