export default {
    'Information collected': [
        'The Company collects various types of information for various purposes, including but not limited to personal identification information, information from third parties to prevent possible fraud, history of transactions (including information about transaction type, amount of transaction, payer`s details etc), history of correspondence with customer service to provide and improve the Company\'s services to You.'
    ],
    'Personal data': [
        `The Company collects various types of information for various purposes, including but not limited to personal identification information, information from third parties to prevent possible fraud, history of transactions (including information about transaction type, amount of transaction, payer\`s details etc), history of correspondence with customer service to provide and improve the Company's services to You.`,

        `In case of verification problems, the Company may ask for additional information about You and Your business by email or postal mail and/or visit your company on-site. Examples of additional information that could be required: utility bills (no older than 3 months) to prove your physical address or bank account statements, Resolution of Directors, Disclosure of Shareholders etc.`,

        `Your personal data may be transferred to and processed at destinations outside the European Economic Area ("EEA") including countries with data protection regulations that differ from those in Your country. They may also be processed by Company employees working outside the EEA who work for the Company or one of the Company's services providers. By using the Company's platform and services, you consent to the transfer of your personal data to other countries`,


    ],

    'Usage data': [
        `While using Company\`s services You agree that the Company may collect information about Your use of the services provided by the Company for general statistics and analysis of site usage, and check for unauthorized access. Usage data includes without limitation Your computer's internet protocol address, browser type, browser version, the pages of the Company services you visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data. The data required by the Company is used to meet all regulatory requirements as well as for security purposes for the Company and its customers to avoid money laundering, possible fraud and other illegal activities.`
    ],

    'Tracking Data and Cookies': [
        `Tracking data, cookies (session, preference, security) and similar technologies are used in order to collect information about You including without limitation the type of device used to access the Company's website, browser, software versions, and actions done on the website. Cookies are text files placed on your computer to collect standard Internet log information and visitor action information. These cookies allow us to distinguish visitors and improve our provision of services. The data required by the Company is used to meet all regulatory requirements as well as for security purposes for the Company and its customers in order to avoid money laundering, possible fraud and other illegal activities.`,

        `The Company uses the information collected to provide and maintain services, to provide customer service and support, to analyze activity done on the website to improve the platform, to notify customers of changes of this Privacy Policy and with other purposes to maintain, improve and secure the provision of services, including platform usability based on customer needs derived from data analysis. Collected data is also used to personalize the communication with You.`,

        `While using the platform, it is possible to disable or delete certain cookies and other tracking technologies on Your personal device. However, it can prevent You from using certain services of the Company.`,

    ],
    'Data security': [
        `The security of Your data is essential to the Company. The Company uses all of the latest data protection tools that meet the highest standards on the market. By using the Company's services, You agree that the Company provides data to those employees who need it to perform their jobs.`,

        `Any data and information provided to the Company are encrypted using advanced technology, as well as the Company\`s website is equipped with necessary security measures to protect against the loss, misuse and alteration of any data and information obtained by the Company.`,

        `When using the Company\`s services and website, You agree that the Company retains the information collected for as long as necessary to provide the services and to comply with all applicable laws and regulations.`,

        `If You are a resident of the European Economic Area (EEA), You have the right to access, update, correct or delete the information we hold about You, and to object to or restrict the processing of Your personal information as well as withdraw Your consent and request a copy of the information the Company has on You.`,
    ],

    'Data collection': [
        `Customer acknowledges and agrees that Cabbagino and Customer are independently acting as data controllers with respect to any personal information processed when providing or using Cabbagino services, respectively. It is explicitly stated that Cabbagino and Customer are not joint data controllers. Cabbagino is a data controller with respect to personal information received from Customer or third parties. Customer is a data controller with respect to personal information received from Cabbagino or third parties.`,

        `Customer acknowledges and accepts that for quality assurance, security and fraud detection purposes, when speaking to members of Cabbagino customer support service, Customer’s call may be monitored and/or recorded.`
    ],

    'Transfer and disclosure of the collected data': [
        `The Company may disclose Your personal data in the good faith belief that such action is necessary to comply with a legal obligation, protect and defend the rights or property of the Company, and prevent or investigate possible wrongdoing in connection with the services, including without limitation any fraud or money-laundering, protect the personal safety of customers or the public, protect against legal liability, provide and analyze services provided.`,

        `For identity validation and verification purposes Cabbagino may make the following information available to other business entities and users to which Customer transfers electronic money or from which Customer receives electronic money transfers: Customer’s name, account number, legal jurisdiction, address, email address and/or IP address`
    ],
    'Third-party': [
        `The Company may employ third-party companies to provide the services on Company’s behalf, and to perform service-related assistance to the Company in the analysis of services.`,

        `There can be paid products and/or services on our website. In that case, third-party services for payment processing (e.g. payment processors) are used and all the data concerning card information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. The Company encourages you to read that Privacy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council.`
    ],

    'Links to third-party resources': [
        'While using the platform, You may find links to other websites that are not operated by the Company. If You click on a third-party resource link, You will be redirected to that third-party resource website. The Company strongly encourages you to read the privacy policy of each site you visit as the Company is not responsible for the information provided and collected on these third-party websites. '
    ],
    'Notifications': [
        'When using the services of the Company, You agree that the Company may send You advertisements about services and products, via emails, text messages, push notifications and other means of communication. You can receive important information at your request. You have the right to change Your preferences for these marketing communications in the platform settings. ',

        `You may also receive system-generated transactional emails such as confirmation of uploads, notification of the receipt of payments, notification of password changes, etc. which are necessary for the proper operation and administration of Your account.`
    ],
    'Privacy Policy Change': [
        `The Company may update this Privacy Policy from time to time. You will be notified about new changes.`
    ],
    'Consent': [
        `By submitting Your personal information You consent to the use of that information as set out in this Policy.`,

        `You further acknowledge and undertake to maintain the confidentiality of information, including the confidentiality of technical and financial information, You receive while using or receiving our services about other business entities, users or Cabbagino, or our technology and You undertake to use this information only in connection with our services.`

    ],
    'Privacy for children': [
        `The Company Services are not intended for persons under the age of 18 ("Children"). The Company does not intentionally collect personal identification information from anyone under 18. If You are a parent or guardian and you know that Your child has provided personal data to the Company, please contact <a href="mailto:support@cabbagino.com">support@cabbagino.com</a>.`
    ]
}