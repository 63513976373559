import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {BrowserRouter as Router, Switch, Route, NavLink, Redirect, useLocation} from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
// Pges
import ForPerson from './pages/ForPerson';
import ForBusiness from './pages/ForBusiness';
import AboutUs from './pages/AboutUs';
import Rules from './pages/Rules';
import Policy from './pages/Policy';
import Security from './pages/Security';

import Header from './elements/Header';
import Footer from './elements/Footer';
import PageSwitch from './elements/PageSwitch';
// Images
import icons from './assets';



const App = () => {
  return (
    <Router>
      <Header />
      <PageSwitch />
      <Footer />
    </Router>
  );
}

export default App;
