import React from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import icons from '../assets';

const AboutUs = () => {
  const {t} = useTranslation();
  return (
    <>
      <div className="hero hero_bg">
        <div className="container container_custom">
          <div className="hero__top">
            <p className="hero__top-text">{t('INTERNATIONAL PAYMENTS')}</p>
            <div>
              <a className="hero__top-link" href="/login" >{t('Sign in')}</a>
              <a className="btn btn_small btn_green" href="/registration">{t('Sign up')}</a>
            </div>
          </div>
          <div className="hero__content">
            <div className="hero__part-text">
              <h1 className="hero__title hero__title_small">{t('Fintech industry experts')}</h1>
              <h3 className="hero__description">{t('Global reach. Innovation. Agility.')}</h3>
              <a className="hero__text" href="https://wallet.cabbagino.com/registration" target="_blank">
                <span>{t('Create an account for free')}</span>
                <img src={icons.arrowRightGray} className="btn__icon" width="23.75" height="25.5" />
              </a>
              <div className="checklist">
                <h3 className="checklist__title">{t('Cabbagino Company')}</h3>
                <ul className="checklist__list">
                  <li className="checklist__item">
                    <div>
                      <p className="checklist__text">{t('is a well-coordinated team of professionals of various fields ')}</p>
                      <a href="mailto:support@cabbagino.com" className="hero__text hero__text_small">
                        {t('Contact us')}
                        <img src={icons.arrowRightGray} className="btn__icon" width="23.75" height="25.5" />
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tiles tiles_transparent">
          <div className="container container_custom">
            <div className="tiles__wrap">
              <div className="tiles__item">
                <img src={icons.tilesIcon31} className="tiles__icon" width="120" height="120" />
                <h4 className="tiles__item-title">{t('Goal')}</h4>
                <p className="tiles__item-text">{t('to provide affordable, fast and convenient money transfer services around the world.')}</p>
              </div>
              <div className="tiles__item">
                <img src={icons.tilesIcon32} className="tiles__icon" width="120" height="120" />
                <h4 className="tiles__item-title">{t('Mission')}</h4>
                <p className="tiles__item-text">{t('to create and implement innovative solutions in the global e-commerce market')}</p>

              </div>
              <div className="tiles__item">
                <img src={icons.tilesIcon33} className="tiles__icon" width="120" height="120" />
                <h4 className="tiles__item-title">{t('Approach')}</h4>
                <p className="tiles__item-text">{t('to apply the competence gained in leading European and international companies')}</p>
              </div>
            </div>
            <div className="tiles__btn-wrap">
              <a  className="btn btn_dark" href="https://wallet.cabbagino.com/registration" target="_blank">
                {t('Create an account for free')}
                <img src={icons.arrowRight} className="btn__icon" width="12" height="12" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
