import React from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import icons from '../assets';

const Dropdown = ({options}) => {
  const {t, i18n } = useTranslation();
  const dropdownRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [])

  React.useEffect(() => {
      const handleClickOutside = (e) => {
          if (dropdownRef.current && !dropdownRef.current.contains(e.target)) setOpen(false);
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      }
  }, [dropdownRef]);

  return (
    <div className={`dropdown ${open ? 'dropdown_open' : ''}`} onClick={() => setOpen(!open)} ref={dropdownRef}>
      <p className="dropdown__selected">
        {t('Table of Contents')}
        <img src={icons.checkMark} className="dropdown__selected-icon" />
      </p>
      <ul className="dropdown__list">
          {
              options.map((e, i) => (
                  <li key={e} className={'dropdown__item'}>
                      <AnchorLink href={`#section${i+1}.`}>{t(e)}</AnchorLink>
                  </li>
              ))
          }
      </ul>
    </div>
  )
}



const OrderItem = ({id, children}) => {
    return <div id={`section${id}`} className={'policy__order__item'}>
        <div className={'policy__item__id'}>{id}</div>
        <div dangerouslySetInnerHTML={{ __html: children }} />
    </div>
}

const OptionList = ({header, options, id}) => {
    return (
        <div className={'policy__item__container'}>
            <p>{header}</p>
            {
                <ul>
                    {options && options.map((e, i) => <li key={`${id}.${i}.`} dangerouslySetInnerHTML={{ __html: e}} />)}
                </ul>
            }
        </div>
    )
}


const Policy = ({ header, data }) => {
  const {t} = useTranslation();
  const sections = Object.keys(data);

    const RenderNode = (node, id = '') => {
        if (typeof node === 'string') return <OrderItem key={id} id={`${id}`} children={t(node)} />;
        if (Array.isArray(node)) {
            return (
                <div className={'policy__item__container'}>
                    {node.map((e, i) => RenderNode(e, `${id}${i+1}.`))}
                </div>
            )
        }
        if (!node.header && !node.options) {
            const sections = Object.keys(node);
            return (
                <>
                    {
                        sections.map((sectionName, sectionId) => (
                            <>
                                <OrderItem id={id || `${sectionId + 1}.`} children={sectionName} />
                                {RenderNode(node[sectionName], id || `${sectionId + 1}.` )}
                            </>
                        ))
                    }
                </>
            )
        }
        return <OptionList id={id} {...node} />
    }

      // const display = [];
      // sections.forEach((sectionName, sectionId) => {
      //     display.push(<OrderSection id={`${sectionId+1}.`} children={t(sectionName)} />)
      //     const section = data[sectionName];
      //     display.push(RenderNode(section, `${sectionId+1}.`))
      // })


    // const RenderInfo = (info, key) => {
    //     if (typeof  info === 'string') return <li key={info} dangerouslySetInnerHTML={{ __html: t(info) }} />;
    //     if (Array.isArray(info)) {
    //         return (
    //             <ol key={`${key} list`}>{info.map((e, i) => RenderInfo(e, `${i}_${key}`))}</ol>
    //         )
    //     }
    //     if (!info.header && !info.options) {
    //         const keys = Object.keys(info);
    //         return (
    //             <ol>
    //                 {
    //                     keys.map((key, i) => (
    //                         <>
    //                             <li key={key} id={`section${i}`}>{t(key)}</li>
    //                             {RenderInfo(info[key], `${i}_${key}`)}
    //                         </>
    //                     ))
    //                 }
    //             </ol>
    //         )
    //     }
    //     return (
    //         <>
    //             <p key={info.header}>{t(info.header)}</p>
    //             <ul key={`${key} ${info.header} options`} >{info.options?.map(e => <li key={e}>{t(e)}</li>)}</ul>
    //         </>
    //     )
    // }

  return (
    <div className="policy">
      <div className="policy__header">
        <div className="container container_custom">
          <div className="hero__top">
            <p className="hero__top-text">{t('INTERNATIONAL PAYMENTS')}</p>
            <div>
              <NavLink to="/login" className="hero__top-link">{t('Sign in')}</NavLink>
              <NavLink to="/registration" className="btn btn_small btn_green">{t('Sign up')}</NavLink>
            </div>
          </div>
            {header}
          </div>
      </div>
      <div className="container container_custom policy__container">
          <Dropdown options={sections} />
          <div className={'policy__content'}>
              {RenderNode(data)}
          </div>
      </div>
    </div>
  );
}

export default Policy;
